import React from "react"
import {LOG} from "../components/tools";
const defaultState = {
  "lastUpdate": {},
  updateLastUpdate: () => {},
}
const DataLoadContext = React.createContext(defaultState)


class DataLoadProvider extends React.Component {
  state = {
    lastUpdate: {},
  }

  updateLastUpdate = (pageLastUpdate) => {
    let lastUpdate = {...this.state.lastUpdate,...pageLastUpdate}
    this.setState({ lastUpdate })
  }

  componentDidMount() {

  }
  render() {
    const { children } = this.props
    const { lastUpdate } = this.state
    return (
      <DataLoadContext.Provider
        value={{
          lastUpdate,
          updateLastUpdate: this.updateLastUpdate,
        }}
      >
        {children}
      </DataLoadContext.Provider>
    )
  }
}

export default DataLoadContext
export { DataLoadProvider }