// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"

// Highlighting for code blocks
import "prismjs/themes/prism.css"


import React from "react"
import { DataLoadProvider } from "./src/context/DataLoadContext"
import {ConsentProvider} from "./src/context/ConsentContext";
import {PersonalizedContentProvider} from "./src/context/PersonalizedContent";
export const wrapRootElement = ({ element }) => (
    <PersonalizedContentProvider><ConsentProvider><DataLoadProvider>
      {element}
    </DataLoadProvider></ConsentProvider></PersonalizedContentProvider>
)

/*
<DataLoadProvider>
    <ConsentProvider>
      {element}
  </ConsentProvider>
  </DataLoadProvider>
 */