import {LOG, S3_PUBLIC} from "../tools";
import { useStaticQuery, graphql } from "gatsby"
import {useMemo} from "react";
import {JSON_TAG_INFO} from "../../../content/tags/tagInfo";


export function getTagInfoFromTagId(tagId){
    if (JSON_TAG_INFO.hasOwnProperty(tagId)){
        return JSON_TAG_INFO[tagId]
    }else{
        return {}
    }
}

export function getUrlImgFromTagId(tagId, width){
    // width  should be in [50,100,400]
    if (JSON_TAG_INFO.hasOwnProperty(tagId)){
        const typeTag = JSON_TAG_INFO[tagId]["t"]
        const name_id = `${typeTag}_${JSON_TAG_INFO[tagId]["n_id"]}`
        const urlImg =  `${S3_PUBLIC}medias/tags/img_${width}_${name_id}.jpg`
        return urlImg
    }
    return  ``
}

export function getUrlImgLowFromTagId(tagId){
    if (JSON_TAG_INFO.hasOwnProperty(tagId)){
        const typeTag = JSON_TAG_INFO[tagId]["t"]
        const directoryName = typeTag=="PP"?"PP":"persons"
        const urlImgLow =  `${S3_PUBLIC}medias/${directoryName}/${JSON_TAG_INFO[tagId]["url"]}`
        return urlImgLow
    }
    return  `${S3_PUBLIC}${"medias/persons/LOW_macron.png"}`
    const urlImgLow =  `${S3_PUBLIC}${"medias/persons/LOW_macron.png"}`
    return  urlImgLow
}
export function getNameFromTagId(tagId){
    if (JSON_TAG_INFO.hasOwnProperty(tagId)){
        return JSON_TAG_INFO[tagId]["n"]
    }
    return  "Inconnu"
}

export function getUserNameFromTagId(tagId){
    if (JSON_TAG_INFO.hasOwnProperty(tagId)){
        const username = JSON_TAG_INFO[tagId]["un"]
        return username!=""?username:null
    }
    return  null
}

export function getTypeFromTagId(tagId){
    if (JSON_TAG_INFO.hasOwnProperty(tagId)){
        return JSON_TAG_INFO[tagId]["t"]
    }
    return  "PP"
}
const JSON_TAG_TYPE_TO_URL = {
    "P":"personnalite",
    "PP": "parti-politique",
    "E": "evenements"
}
export function getFullUrlFromTagTypeAndUrl(tag_type,tag_url){
    let url_type
    if (JSON_TAG_TYPE_TO_URL.hasOwnProperty(tag_type)){
        url_type = JSON_TAG_TYPE_TO_URL[tag_type]
    }else{
        url_type = tag_type
    }

    return `/${url_type}/${tag_url}`
}
let LIST_ALL_TAG_ID_VOCAB = []

export default LIST_ALL_TAG_ID_VOCAB

export let LIST_ALL_PERSON = []
export let LIST_ALL_VOCAB = []
export let LIST_ALL_PARTI = []
Object.keys(JSON_TAG_INFO).forEach(function(tag_id){
  const tag_info = JSON_TAG_INFO[tag_id]
    const tag_info_tmp = {...tag_info,id:tag_id}
    if( tag_info["t"] =="V"){
      LIST_ALL_TAG_ID_VOCAB.push(tag_id)
      LIST_ALL_VOCAB.push(tag_info_tmp)
  }
  if( tag_info["t"] =="P" && (tag_info.hasOwnProperty("v")==false || tag_info["v"]!=0)){

      LIST_ALL_PERSON.push(tag_info_tmp)
  }else if( tag_info["t"] =="PP"){
      LIST_ALL_PARTI.push(tag_info_tmp)
  }
})
const LIST_TAG_ID_CANDIDAT = ["1","8","7","26","11","13","18","39"]
export const SET_TAG_ID_CANDIDAT = new Set(LIST_TAG_ID_CANDIDAT)

export const theme_candidats = {
    1:{500:"#f59a36" ,200:"" },
    8:{500:"#030031" ,200:"" },
    13:{500:"#296099" ,200:"" },
    18:{500:"#f7a4bd" ,200:"" },
    7:{500:"#ba191c" ,200:"" },
    11:{500:"#4c936a" ,200:"" },
    26:{500:"#8f42f4" ,200:"" },
    38:{500:"#f7a4bd" ,200:"" },
    39:{500:"#ba191c" ,200:"" }
}