export const JSON_TAG_INFO = {
1: {
t:"P",
n:"Emmanuel Macron",
url:"emmanuel-macron",
un:"EmmanuelMacron",
n_id:"Macron",
pp:[5]
},
8: {
t:"P",
n:"Marine Le Pen",
url:"marine-le-pen",
un:"MLP_officiel",
n_id:"MLP",
pp:[3]
},
15: {
t:"P",
n:"Arnaud Montebourg",
url:"arnaud-montebourg",
un:"montebourg",
n_id:"Montebourg",
pp:[9]
},
7: {
t:"P",
n:"Jean-Luc Mélenchon",
url:"jean-luc-melenchon",
un:"JLMelenchon",
n_id:"JLM",
pp:[2]
},
26: {
t:"P",
n:"Eric Zemmour",
url:"eric-zemmour",
un:"ZemmourEric",
n_id:"EZ",
pp:[37]
},
11: {
t:"P",
n:"Yannick Jadot",
url:"yannick-jadot",
un:"yjadot",
n_id:"YJ",
pp:[6]
},
17: {
t:"P",
n:"Delphine Batho",
url:"delphine-batho",
un:"delphinebatho",
n_id:"batho",v:0,
pp:[6]
},
20: {
t:"P",
n:"Jean-Marc Governatori",
url:"jean-marc-governatori",
un:"JM_Governatori",
n_id:"governatori",v:0,
pp:[6]
},
19: {
t:"P",
n:"Eric Piolle",
url:"eric-piolle",
un:"EricPiolle",
n_id:"piolle",v:0,
pp:[6]
},
16: {
t:"P",
n:"Sandrine Rousseau",
url:"sandrine-rousseau",
un:"sandrousseau",
n_id:"srousseau",
pp:[6]
},
10: {
t:"P",
n:"Olivier Faure",
url:"olivier-faure",
un:"faureolivier",
n_id:"OF",v:0,
pp:[9]
},
21: {
t:"P",
n:"Michel Barnier",
url:"michel-barnier",
un:"MichelBarnier",
n_id:"barnier",
pp:[4]
},
12: {
t:"P",
n:"Xavier Bertrand",
url:"xavier-bertrand",
un:"xavierbertrand",
n_id:"XB",
pp:[4]
},
13: {
t:"P",
n:"Valérie Pécresse",
url:"valerie-pecresse",
un:"vpecresse",
n_id:"VP",
pp:[4]
},
14: {
t:"P",
n:"Laurent Wauquiez",
url:"laurent-wauquiez",
un:"laurentwauquiez",
n_id:"LW",v:0,
pp:[4]
},
36: {
t:"P",
n:"Eric Ciotti",
url:"eric-ciotti",
un:"ECiotti",
n_id:"ciotti",
pp:[4]
},
18: {
t:"P",
n:"Anne Hidalgo",
url:"anne-hidalgo",
un:"Anne_Hidalgo",
n_id:"hidalgo",
pp:[9]
},
38: {
t:"P",
n:"Christiane Taubira",
url:"christiane-taubira",
un:"ChTaubira",
n_id:"taubira",
pp:[9]
},
40: {
t:"P",
n:"Nicolas Dupont-Aignan",
url:"nicolas-dupont-aignan",
un:"dupontaignan",
n_id:"NDA",
pp:[]
},
39: {
t:"P",
n:"Fabien Roussel",
url:"fabien-roussel",
un:"Fabien_Roussel",
n_id:"roussel",
pp:[41]
},
42: {
t:"P",
n:"Gaspard Koenig",
url:"gaspard-koenig",
un:"MouvementSimple",
n_id:"gp",
pp:[]
},
5: {
t:"PP",
n:"La République en Marche",
url:"la-republique-en-marche",
un:"enmarchefr",
n_id:"LREM",
pp:[]
},
6: {
t:"PP",
n:"EELV",
url:"eelv",
un:"EELV",
n_id:"EELV",
pp:[]
},
3: {
t:"PP",
n:"Le Rassemblement National",
url:"le-rassemblement-national",
un:"RNational_off",
n_id:"RN",
pp:[]
},
2: {
t:"PP",
n:"La France Insoumise",
url:"la-france-insoumise",
un:"FranceInsoumise",
n_id:"LFI",
pp:[]
},
4: {
t:"PP",
n:"Les Républicains",
url:"les-republicains",
un:"lesRepublicains",
n_id:"LR",
pp:[]
},
9: {
t:"PP",
n:"Le Parti Socialiste",
url:"le-parti-socialiste",
un:"partisocialiste",
n_id:"PS",
pp:[]
},
37: {
t:"PP",
n:"Reconquête",
url:"reconquete",
un:"Reconquete2022",
n_id:"R",
pp:[]
},
41: {
t:"PP",
n:"Parti Communiste",
url:"parti-communiste",
un:"PCF",
n_id:"PCF",
pp:[]
},
23: {
t:"E",
n:"La primaire écologiste 2022",
url:"la-primaire-ecologiste-2022",
un:"",
n_id:"primeco22",
pp:[]
},
22: {
t:"E",
n:"L'élection présidentielle 2022",
url:"l'election-presidentielle-2022",
un:"",
n_id:"president22",
pp:[]
},
34: {
t:"V",
n:"Nucléaire",
url:"nucleaire",
un:"",
n_id:"nucléaire",
pp:[]
},
24: {
t:"V",
n:"Climat",
url:"climat",
un:"",
n_id:"climat",
pp:[]
},
27: {
t:"V",
n:"Sécurité",
url:"securite",
un:"",
n_id:"sécurité",
pp:[]
},
25: {
t:"V",
n:"Education",
url:"education",
un:"",
n_id:"education",
pp:[]
},
28: {
t:"V",
n:"Santé",
url:"sante",
un:"",
n_id:"santé",
pp:[]
},
29: {
t:"V",
n:"Covid",
url:"covid",
un:"",
n_id:"covid",
pp:[]
},
31: {
t:"V",
n:"Ecologie",
url:"ecologie",
un:"",
n_id:"ecologie",
pp:[]
},
32: {
t:"V",
n:"Immigration",
url:"immigration",
un:"",
n_id:"immigration",
pp:[]
},
35: {
t:"V",
n:"Transport",
url:"transport",
un:"",
n_id:"transport",
pp:[]
},
30: {
t:"V",
n:"Religion",
url:"religion",
un:"",
n_id:"religion",
pp:[]
},
33: {
t:"V",
n:"Justice",
url:"justice",
un:"",
n_id:"justice",
pp:[]
}
}