import React from "react"
import {LOG} from "../components/tools";

const defaultState = {
  "consent_ga": false,
    consentIsLoaded:false,
    firstAskConsent:true,
  updateConsent: (consent) => {},
}
const ConsentContext = React.createContext(defaultState)

class ConsentProvider extends React.Component {
  state = {
    consent_ga: false,
      consentIsLoaded:false,
      firstAskConsent:true,
  }
  updateConsent = (consent) => {
    const {consent_ga} = consent
      localStorage.setItem("ve_consent_ga", JSON.stringify(consent_ga))
      this.setState({ consent_ga:consent_ga })
  }

  componentDidMount() {
   const consent_ga = JSON.parse(localStorage.getItem("ve_consent_ga"))
      let json_state_update = {
       consentIsLoaded:true
      }
  if(consent_ga!=null){
    json_state_update["firstAskConsent"] = false
  }
  if (consent_ga == true) {
        json_state_update["consent_ga"] =true
    }
    this.setState({...json_state_update})
  }
  render() {
    const { children } = this.props
    const { consent_ga,consentIsLoaded,firstAskConsent } = this.state
    return (
      <ConsentContext.Provider
        value={{
          consent_ga,
          consentIsLoaded,
            firstAskConsent,
          updateConsent: this.updateConsent,
        }}
      >
        {children}
      </ConsentContext.Provider>
    )
  }
}

export default ConsentContext
export { ConsentProvider }