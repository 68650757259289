export const DEBUG = (process.env.DEBUG==1)
export const LOG = DEBUG ? console.log.bind(console) : function () {};
export const S3_PUBLIC = "https://voice-election-public.s3.fr-par.scw.cloud/"


export function addMinutes(date, minutes) {
    return new Date(date.getTime() + minutes*60000);
}

export function hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
}

