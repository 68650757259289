import React from "react"
import {LOG} from "../components/tools";
import {SET_TAG_ID_CANDIDAT} from "../components/tag/tools";

const defaultState = {
    jsonTagVisible: {"P":new Set()},
  updateSetTagsVisible: (tagType,listTag) => {},
}
const PersonalizedContentContext = React.createContext(defaultState)
const LIST_PERSONALIZED_TAG_TYPE = ["P","PP","V"]
const VERSION_CONTEXT= "1"

class PersonalizedContentProvider extends React.Component {
  state = {
    jsonTagVisible: {},
      localContentLoaded:false
  }
  updateSetTagsVisible = (tagType,listTag) => {
        let json_update = {}
      let jsonTagVisible = {...this.state.jsonTagVisible}
      jsonTagVisible[tagType] = new Set(listTag)
      json_update["jsonTagVisible"] = {...jsonTagVisible}
      this.save_content_tag_type(tagType,jsonTagVisible[tagType])
      this.setState(json_update)
  }
  save_content_tag_type(tag_type,contentSet){
      localStorage.setItem(`personnalized_visible_V${VERSION_CONTEXT}_${tag_type}`, JSON.stringify(Array.from(contentSet)))
  }
  get_local_content_visible_tag_type(tag_type){
      const content_visible= JSON.parse(localStorage.getItem(`personnalized_visible_V${VERSION_CONTEXT}_${tag_type}`))
      if (content_visible!=null){
          return new Set(content_visible)
      }else {
          if( tag_type=="P"){
              return SET_TAG_ID_CANDIDAT
          }
          return null
      }
  }

  componentDidMount() {
      let jsonTagVisible = {}

      LIST_PERSONALIZED_TAG_TYPE.forEach((tag_type)=>{
          const contentTagType = this.get_local_content_visible_tag_type(tag_type)
           if(contentTagType!=null){
              jsonTagVisible[tag_type] = contentTagType
          }
      })


      this.setState({localContentLoaded:true,jsonTagVisible})


  }

  render() {
    const { children } = this.props
    const { jsonTagVisible,localContentLoaded} = this.state
    return (
      <PersonalizedContentContext.Provider
        value={{
          jsonTagVisible,
            localContentLoaded,
          updateSetTagsVisible: this.updateSetTagsVisible,
        }}
      >
        {children}
      </PersonalizedContentContext.Provider>
    )
  }
}

export default PersonalizedContentContext
export { PersonalizedContentProvider }