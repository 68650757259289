// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-analyses-js": () => import("./../../../src/pages/analyses.js" /* webpackChunkName: "component---src-pages-analyses-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lab-js": () => import("./../../../src/pages/lab.js" /* webpackChunkName: "component---src-pages-lab-js" */),
  "component---src-pages-le-projet-js": () => import("./../../../src/pages/le-projet.js" /* webpackChunkName: "component---src-pages-le-projet-js" */),
  "component---src-pages-methodologie-js": () => import("./../../../src/pages/methodologie.js" /* webpackChunkName: "component---src-pages-methodologie-js" */),
  "component---src-pages-parti-politique-js": () => import("./../../../src/pages/parti-politique.js" /* webpackChunkName: "component---src-pages-parti-politique-js" */),
  "component---src-pages-personnalite-js": () => import("./../../../src/pages/personnalite.js" /* webpackChunkName: "component---src-pages-personnalite-js" */),
  "component---src-templates-analyse-template-js": () => import("./../../../src/templates/analyse-template.js" /* webpackChunkName: "component---src-templates-analyse-template-js" */),
  "component---src-templates-parti-template-js": () => import("./../../../src/templates/parti-template.js" /* webpackChunkName: "component---src-templates-parti-template-js" */),
  "component---src-templates-person-template-js": () => import("./../../../src/templates/person-template.js" /* webpackChunkName: "component---src-templates-person-template-js" */)
}

