import { extendTheme } from "@chakra-ui/react"

export const SFontTitle = " 'Playfair Display SC', serif"
export const SFontBody = "EB Garamond, serif"
export const SHeightMenuTop = 40

export const LIST_COLOR_NAME = ["orange","yellow","green","teal","blue","cyan","purple","pink"]

const json_theme = {
  fonts: {
    body: SFontBody,
    heading: SFontTitle,
    mono: "Menlo, monospace",
  },
  letterSpacings: {
    tighter: "-0.05em",
    tight: "-0.025em",
    normal: "1em",
    wide: "0.025em",
    wider: "0.05em",
    widest: "0.1em",
  },

  colors: {
    black:"#0B0B08",
    border:"#827A5E",
    text:{
        500:"#0B0B08" ,//
  300:"#232119", //
  200:"#585840", //
  100: "#827A5E",
  50: "#BAB5A0"
    },


    green:{
       900 : '#364334',
      800:'#5c7259',
    700 : '#749071',
      600:'#87a884',
    500 : '#97C1A9',
      400:'#adccaa',
    300 : '#c2d9c0',
    200 : '#d5e5d4',
    100 : '#f5f5f5',
      50:'#f7faf7'
    },
  "gold":{
  50: '#fdf5df',
  100: '#f4e5ba',
  200: '#ebd792',
  300: '#e3cb68',
  400: '#dbb23f',
  500: '#c28e26',
  600: '#97661c',
  700: '#6c4212',
  800: '#412207',
  900: '#190800'
    },
    "goldOld": {
      "50": "#FBF8E9",
      "100": "#F4EAC3",
      "200": "#EDDD9C",
      "300": "#E6D075",
      "400": "#DFC24E",
      "500": "#D8B527",
      "600": "#AC9120",
      "700": "#816D18",
      "800": "#564810",
      "900": "#2B2408"
    },
    red:{
       900 : '#513a38',
      800:'#8a6260',
    700 : '#af7d79',
      600:'#cb918d',
    500 : '#e4a39e',
      400:'#f9b2ad',
    300 : '#ffc5c1',
    200 : '#ffd8d5',
    100 : '#ffe8e7',
      50:'#fff8f7'
    },
    bg:{
      900:"#D8D5C9",
    700:"#E9E8E1",
    500:"#F2F1ED",
    300: "#FBFAF9"
    },

    secondary:
{
  50: '#fef4df',
  100: '#f5e3ba',
  200: '#ecd491',
  300: '#e5c767',
  400: '#ddab3d',
  500: '#c48824',
  600: '#98611a',
  700: '#6d3e11',
  800: '#422006',
  900: '#190700',
},
    primary:

{
  50: '#def8ff',
  100: '#b9e4f6',
  200: '#91d1ec',
  300: '#68bfe3',
  400: '#41acda',
  500: '#2a93c1',
  600: '#1c7297',
  700: '#0f516d',
  800: '#003244',
  900: '#00111c',
}
 },
  shadows :{
  sm: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;",
  md: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
  lg: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
  xl:
    "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
  "2xl": "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
  outline: "0 0 0 3px rgba(66, 153, 225, 0.6)",
  inner: "inset 0 2px 4px 0 rgba(0,0,0,0.06)",
  none: "none",
    card:'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
    cardItem:'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;'

}
}

const theme = extendTheme(json_theme)

export default theme
